import colors from './colors';

/**
 * Font base
 */

const fontBase = 16;

/**
 * This is our custom theme where we define global styles.
 * It should serve as a guideline for styling, but not all styles *have* to be taken from here.
 */
const breakpoints = ['33.375em', '48em', '62em', '75em'];

// Aliases
/* eslint-disable */
breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];
breakpoints.lg = breakpoints[2];
breakpoints.xl = breakpoints[3];
/* eslint-enable */

const bpAliases = {
  phone: breakpoints[0],
  tablet: breakpoints[1],
  desktop: breakpoints[2],
  large: breakpoints[3]
};

/**
 * Space is used for margin and padding scales.
 * It's recommended to use powers of two to ensure alignment across the entire project
 */
const space = [0, 4, 8, 16, 32, 64, 128, 256, 512];

/**
 * Typographic scale
 */
const fontSizes = [12, 16, 20, 36, 41];

const lineHeights = [15, 16, 19, 24, 36, 40];

const fontWeights = {
  normal: 400,
  medium: 500,
  semiBold: 600,
  bold: 700
};

/**
 * Letter-spacing should vary, depending on usage of text
 */
const letterSpacings = {
  normal: 'normal',
  menuItem: '0.008em',
  buttons: '-0.054em',
  text: '0.007em',
  label: '0.006em',
  h1: '-0.101rem',
  h2: '-0.077em',
  h3: '0.011em'
};

/**
 * Border-radius
 */
const radii = [0, 2, 4, 8, 16, 18, 32];

/**
 * Common variables
 */
const variables = {
  buttonHeight: '2.313em',
  buttonPrimaryWidth: '8.313em'
};

const theme = {
  name: 'Default',
  breakpoints,
  bpAliases,
  colors,
  space,
  variables,
  fontSizes,
  lineHeights,
  fontWeights,
  letterSpacings,
  radii,
  fontBase
};

export default theme;
