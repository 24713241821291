export default {
  primary: '#5F0082',
  text: '#4A90E2',
  white: '#FFFFFF',
  black: '#000000',
  grey: '#A2A2A2',
  pink: 'rgba(247, 119, 212, 1)',
  lightBlue: '#F1F8FB',
  browser: {
    red: '#ed1c24',
    orange: '#f7941d',
    green: '#8dc63f'
  },
  background: {
    pink: '#FFBDED',
    blue1: '#A3DBF4',
    blue2: '#BAEAFF',
    yellow: '#F8E71C'
  }
};
